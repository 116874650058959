import { ITypeForm, IModelWf } from "../Workflow/interfaces/IWorkflow";
import { IListModelTreeview } from '../parametersModels/interfaces/IListModelTreeview';

// Fonction qui retourne un objet URLSearchParams pour pouvoir manipuler les param dans l'url
export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(window.location.search);
}

// Fonction pour construire l'arborescence des modèles
export const constructTree = async (modeles: any[], typesForm: ITypeForm[]) => {
  const modelesParent: any[] = modeles.filter(modele => modele.idParent == null);
  const testResult: any = [];
  if (modelesParent.length > 0) {
    for (const modeleParent of modelesParent) {
      const children = await getChildren(modeleParent, modeles);
      testResult.push({ key: modeleParent.id.toString(), id: modeleParent.id, label: modeleParent.name, nodes: children, isGRC: modeleParent.isGRC, isForm: modeleParent.isForm, formType: modeleParent.formType, guid: modeleParent.guid });
    }
    return { treeModeles: testResult, allModelsList: modeles, allTypeForm: typesForm };
  }
  else {
    testResult.push({ key: "Pas de modèle pour le moment", label: "Pas de modèle pour le moment", name: "Pas de modèle pour le moment", id: "noModel" });
    return { treeModeles: testResult, allModelsList: [], allTypeForm: typesForm };
  }
}

// Fonction de récurssivitée pour construire l'arbre des modèles
const getChildren = async (modeleParent, allModeles: any[]) => {
  const treeChildren: any[] = [];
  const children: any[] = allModeles.filter(modele => modele.idParent == modeleParent.id);
  for (const child of children) {
    const usersDirectReports = await getChildren(child, allModeles);

    usersDirectReports ? treeChildren.push({ key: child.id.toString(), id: child.id, label: child.name, nodes: usersDirectReports, isGRC: child.isGRC, isForm: child.isform, formType: child.formType, guid: child.guid }) :
      treeChildren.push({ title: child });
  }
  return treeChildren;
}

/******************************** Fonctions pour le picker des modèles de circuit *****************************************/
// Fonction pour fabriquer l'arbre du Treeview
export const getModelsTreeview: (allWFModels: IModelWf[]) => IListModelTreeview[] = (allWFModels: IModelWf[]) => {
  const allModels: IModelWf[] = allWFModels != undefined && allWFModels != null && allWFModels.length > 0 ? allWFModels : [];
  const allFather: IModelWf[] = allModels.filter(model => model.idParent == null);
  const allModelsFormated: IListModelTreeview[] = allFather.map(father => {
    const children: IListModelTreeview[] = getChildrensModelTreeview(father, allModels);
    const itemList: IListModelTreeview = {
      label: father.name,
      value: father.id,
      children: children
    };
    return itemList;
  });

  return allModelsFormated;
}


// Fonction qui cherche les enfants des modèles pour fabriquer l'arbre du Treeview
export const getChildrensModelTreeview = (modelParent: any, allModels: IModelWf[]): IListModelTreeview[] => {
  const findChildren: IModelWf[] = allModels.filter(mod => mod.idParent == modelParent.id);
  if (findChildren.length > 0) {
    const allChildrens: IListModelTreeview[] = findChildren.map(child => {
      const childrens: IListModelTreeview[] = getChildrensModelTreeview(child, allModels);
      return {
        label: child.name,
        value: child.id,
        children: childrens
      };
    });
    return allChildrens;
  }
  else {
    return [];
  }
}

export const foundModelValueInTreeview = (childrenModels: IListModelTreeview[], modelIds?: number[]) => {
  childrenModels.forEach(model => {
    if (modelIds == undefined || modelIds == null || modelIds.length == 0) {
      model.checked = false;
    }
    else {
      if (modelIds.find(id => id == model.value) != undefined) {
        model.checked = true;
      }
    }

    if (model.children != undefined && model.children != null && model.children.length > 0) {
      foundModelValueInTreeview(model.children, modelIds);
    }
  });
}
/***************************************************************************************************************************/