import * as React from 'react';
import { observer } from "mobx-react";
import TextField from '@material-ui/core/TextField';
import Store from '../../store/store';
import SelectReact, { createFilter } from 'react-select';
import axios from 'axios';
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import { customSingleValue, formatGroupLabel, GroupedOption, MenuList, Option } from '../../helper/reactSelectCustomRender';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IModelDelegation } from '../../delegation/interfaces/delegation';
import Spinner from '../../Spinner/Spinner';
import { IModelWf, ITypeForm } from '../../Workflow/interfaces/IWorkflow';
const styles = require("../styles/Delegation.scss");

interface Props {

}

interface State {
    optionModels: (GroupedOption | Option)[];
    selectedModel: number;
    selectedUserTo: number;
    selectedUserFrom: number;
    id: number;
    name: string;
    loadingAddEditButton: boolean;
}

@observer

class MenuModelDeleg extends React.Component<RouteComponentProps & Props, State> {
    public constructor(props: RouteComponentProps & Props) {
        super(props)
        this.state = {
            optionModels: [],
            selectedModel: undefined,
            selectedUserFrom: Store.userConnected.id,
            selectedUserTo: undefined,
            id: undefined,
            name: undefined,
            loadingAddEditButton: false
        }
    }
    onChangeUserTo = (option) => {
        if (option != null && option.value != this.state.selectedUserFrom) {
            this.setState({
                selectedUserTo: option.value
            });
        }
        else {
            this.setState({
                selectedUserTo: undefined
            });
        }
    }
    onChangeUserFrom = (option) => {
        if (option != null && option.value != this.state.selectedUserTo && option.value != Store.userConnected.id) {
            this.setState({
                selectedUserFrom: option.value
            });
        }
        else {
            this.setState({
                selectedUserFrom: undefined
            });
        }
    }
    updateReactSelect = (user: any) => {
        const myUser = Store.suggestionsUser.find(suggestion => suggestion.value == user);
        return myUser;
    }
    getAllModelWf = () => {
        axios.get(`${Store.wsPath}/1/workflows/getAllModelWf`).then(responseModels => {
            axios.get(Store.wsPath + `/1/formulaire/GetAllFormType`).then(resTypes => {
                // On récupére les modèles parents
                const allParentModel: IModelWf[] = responseModels.data.filter(model => model.idParent == null || model.idParent == undefined);
                const allFormTypes: ITypeForm[] = resTypes.data;

                const allModelGrouped: (GroupedOption | Option)[] = [];
                // On cherche si il y a des formTypes activés pour les classer par form type
                if (allFormTypes.length > 0) {
                    allFormTypes.forEach(formType => {
                        const allModelFormType = allParentModel.filter(model => model.isForm == true && model.formType == formType.name);
                        if (allModelFormType.length > 0) {
                            allModelGrouped.push({
                                label: formType.label,
                                options: allModelFormType.map(model => { return { value: model.id, label: model.name } })
                            });
                        }
                    });
                }

                // On joute les modèles Lyflow au début du tableau
                const allModelLyflowFormated: Option[] = allParentModel.filter(model => model.isForm != true).map(model => { return { value: model.id, label: model.name } });
                allModelGrouped.unshift({
                    label: 'Lyflow',
                    options: allModelLyflowFormated,
                });

                // On ajoute la possibilité de choisir tous les modéles au début
                allModelGrouped.unshift({ value: 0, label: "Tous les modèles" });

                this.setState({ optionModels: allModelGrouped }, () => {
                    // pour afficher le nom du modèle a l'édition
                    if (Store.contextualmenu.content === "editModelDeleg") {
                        this.loadModelDeleg();
                    }
                });
            }).catch(error => {
                console.error("Error 'getAllModelWf' formType : ", error);
            });
        }).catch(error => {
            console.error("Error 'getAllModelWf' : ", error);
        });
    }
    getModelById = (id: number) => {
        let findModel: Option = null;
        this.state.optionModels.forEach(mod => {
            const model = mod as GroupedOption;
            if (model.options != undefined && model.options != null) {
                const findInChild = model.options.find(mo => mo.value === id);
                if (findInChild != undefined) {
                    findModel = findInChild;
                }
            }
            else {
                const model = mod as Option;
                if (model.value === id) {
                    findModel = model;
                }
            }
        });
        return findModel;
    }
    componentDidMount(): void {
        this.getAllModelWf();
    }
    handlerModel = (option) => {
        this.setState({
            selectedModel: option.value
        });
    }
    handlerName = (e) => {
        e.persist();
        this.setState({
            name: e.target.value
        });
    }
    loadModelDeleg = () => {
        if (Store.modelDelegation != undefined) {
            this.setState({
                id: Store.modelDelegation.model.id,
                name: Store.modelDelegation.model.name,
                selectedModel: Store.modelDelegation.model.idModel != undefined && Store.modelDelegation.model.idModel != null ? Store.modelDelegation.model.idModel : 0,
                selectedUserTo: Store.modelDelegation.model.delegateTo,
                selectedUserFrom: Store.modelDelegation.model.delegateFrom,
            })
        }
    }
    getDateFormat = (date: Date) => {
        let dateFormated: string = "Pas de date";
        if (date != null) {
            const year = date.getFullYear().toString();
            const month = date.getMonth() + 1;
            const day = date.getDate().toString();
            dateFormated = year + "-" + (month < 10 ? "0" + month.toString() : month.toString()) + "-" + day;
        }
        return dateFormated;
    }
    didExist = (model: IModelDelegation) => {
        const temp = Store.modelDelegation.all.filter(m => m.id != model.id && m.idModel === model.idModel && m.delegateFrom === model.delegateFrom && m.delegateTo === model.delegateTo)
        if (temp.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    addModelDeleg = (event) => {
        event.preventDefault();
        this.setState({ loadingAddEditButton: true }, () => {
            const modelDeleg = {
                id: 0,
                name: this.state.name,
                idModel: this.state.selectedModel,
                delegateFrom: this.state.selectedUserFrom,
                delegateTo: this.state.selectedUserTo,
                createdBy: Store.userConnected.id
            }
            if (!this.didExist(modelDeleg)) {
                axios.post(Store.wsPath + "/1/delegation/CreateModelDelegation", modelDeleg).then((response) => {
                    this.props.history.push("/refresh?redir=delegation")
                }).catch(error => {
                    console.error("Error 'addModelDeleg' : ", error);
                    this.setState({ loadingAddEditButton: false });
                    Store.snackBar = {
                        open: true,
                        error: true,
                        message: "Une erreur est survenue lors de l'ajout du modèle"
                    }
                });
            } else {
                Store.snackBar = {
                    open: true,
                    error: true,
                    message: "Ce modèle existe déjà."
                }
                this.setState({ loadingAddEditButton: false });
            }
        });
    }
    editModelDeleg = (event) => {
        event.preventDefault()
        const modelDeleg = {
            id: this.state.id,
            name: this.state.name,
            idModel: this.state.selectedModel,
            delegateFrom: this.state.selectedUserFrom,
            delegateTo: this.state.selectedUserTo,
            createdBy: Store.userConnected.id,
            isDesactivate: Store.modelDelegation.model.isDesactivate
        }
        if (!this.didExist(modelDeleg)) {
            axios.post(Store.wsPath + "/1/delegation/UpdateModelDelegationById", modelDeleg)
                .then(response => {
                    this.props.history.push("/refresh?redir=delegation")
                })
                .catch(error => {
                    console.error("ERROR 'editModelDeleg': ", error)
                })
        } else {
            Store.snackBar = {
                open: true,
                error: true,
                message: "Ce modèle existe déjà."
            }
        }
    }

    public render(): JSX.Element {
        return (
            <form key="deleg" autoComplete="false"
                onSubmit={Store.contextualmenu.content === "addNewModelDeleg" ? this.addModelDeleg : this.editModelDeleg}
            >
                <div style={{ position: "relative" }}>
                    <h5>{Store.contextualmenu.content === "editModelDeleg" ? "Modifier un modèle" : "Nouveau Modèle"}</h5>
                    <div className={styles.space}></div>
                    <InputLabel>Nom de la délégation</InputLabel>
                    <TextField
                        variant='outlined'
                        type="text"
                        required
                        className={styles.datePicker}
                        InputLabelProps={{ shrink: true }}
                        value={this.state.name}
                        onChange={this.handlerName}
                    />
                    <div className={styles.space}></div>
                    <InputLabel>Choisir un circuit</InputLabel>
                    <div style={{ position: "relative" }}>
                        <SelectReact
                            placeholder="Circuits..."
                            options={this.state.optionModels}
                            filterOption={createFilter({ ignoreAccents: false })}
                            value={this.state.selectedModel != undefined ? this.getModelById(this.state.selectedModel) : null}
                            styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }), menu: base => ({ ...base, zIndex: 99999 }) }}
                            onChange={this.handlerModel}
                            required
                            isClearable
                            formatGroupLabel={formatGroupLabel}
                        />
                        <input className={styles.hiddenInput} required value={this.state.selectedModel != undefined ? this.getModelById(this.state.selectedModel).value : ""} ></input>
                    </div>
                    {Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin" ?
                        <>
                            <div className={styles.space}></div>
                            <InputLabel>Personne à décharger :</InputLabel>
                            <div style={{ position: "relative" }}>
                                <SelectReact
                                    placeholder="Utilisateurs..."
                                    options={Store.suggestionsUser}
                                    onChange={option => this.onChangeUserFrom(option ? option : null)}
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    value={this.state.selectedUserFrom != undefined ? this.updateReactSelect(this.state.selectedUserFrom) : null}
                                    styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                                    components={{ SingleValue: customSingleValue, Option: MenuList }}
                                    isClearable
                                />
                                <input className={styles.hiddenInput} required value={this.state.selectedUserFrom != undefined ? this.updateReactSelect(this.state.selectedUserFrom).value : ""} ></input>
                            </div>
                        </>
                        : ""}
                    <div className={styles.space}></div>
                    <InputLabel>Personne à déléguer :</InputLabel>
                    <div style={{ position: "relative" }}>
                        <SelectReact
                            placeholder="Utilisateurs..."
                            required
                            options={Store.suggestionsUser}
                            onChange={option => this.onChangeUserTo(option ? option : null)}
                            filterOption={createFilter({ ignoreAccents: false })}
                            value={this.state.selectedUserTo != undefined ? this.updateReactSelect(this.state.selectedUserTo) : null}
                            styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                            components={{ SingleValue: customSingleValue, Option: MenuList }}
                            isClearable
                        />
                        <input className={styles.hiddenInput} required value={this.state.selectedUserTo != undefined ? this.updateReactSelect(this.state.selectedUserTo).value : ""} ></input>
                    </div>
                    <div className={styles.space}></div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={this.state.loadingAddEditButton == true ? true : false}
                    >
                        {
                            this.state.loadingAddEditButton == true ?
                                <Spinner size={21} />
                                :
                                Store.contextualmenu.content === "editModelDeleg" ?
                                    "Modifier"
                                    :
                                    "Valider"
                        }
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        className={styles.btnCancel}
                        color="default"
                        onClick={() => { Store.contextualmenu.open = "hidden"; Store.contextualmenu.content = undefined; }}
                    >
                        Annuler
                    </Button>
                </div>
            </form>
        )
    }
}
export default withRouter(MenuModelDeleg);