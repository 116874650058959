import * as React from 'react'
import { components } from 'react-select';
import { css } from 'emotion';


export interface GroupedOption {
    readonly label: string;
    readonly options: Option[];
}

export interface Option {
    readonly value: string | number;
    readonly label: string;
}


export const MenuList = ({ cx, children, getStyles, innerRef, ...props }) => {
    return (
        <div ref={innerRef} title={props.data.mail} {...props.innerProps} className={cx(css(getStyles('option', props)), { 'option': true, 'option--is-disabled': props.innerProps.isDisabled, 'option--is-focused': props.innerProps.isFocused, 'option--is-selected': props.innerProps.isSelected, })}>
            <span>{props.data.label}​​​​​</span>
        </div>
    );
}

export const customSingleValue = ({ data }) => {
    return (
        <div className="input-select" >
            <div className={" input-select__single-value"} title={data.mail}>
                <span>{data.label}</span>
            </div>
        </div>
    )
};

export const customMultipleValue = ({ ...props }) => {
    return (
        <div style={{ width: "85%" }} title={props.data.mail}>
            <components.MultiValueLabel {...props} />
        </div>
    )
};

// Composant utilisé lors des affichages groupés dans react select
export const formatGroupLabel = (data: GroupedOption) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>{data.label}</span>
        <span style={{ backgroundColor: '#EBECF0', borderRadius: '2em', color: '#172B4D', display: 'inline-block', fontSize: 12, fontWeight: 'normal', lineHeight: '1', minWidth: 1, padding: '0.16666666666667em 0.5em', textAlign: 'center', }}>
            {data.options.length}
        </span>
    </div>
);