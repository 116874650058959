import * as React from "react";
import Store from '../store/store';
import { observer } from "mobx-react";
import { IDBWorkflow, IDBWfStep, IDBWfTask } from "./interfaces/IWorkflow";
import styles from "./styles/workflowNew.module.scss";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import People from '@material-ui/icons/People';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import AccessTime from '@material-ui/icons/AccessTime';
import Orientation from "./Orientation";
import Signature from "./Signature";
import Check from '@material-ui/icons/Check';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "./../helper/helpers";

interface IWorkflowProps {
  workflow: IDBWorkflow;
  mode: string;
  idSelectedStep: number;
  idSelectedTask?: number;
}

interface IWorkflowState {
  toggleStep: number[];
  toggleAccordionCollabStep: number[];
}


@observer
class Workflow extends React.Component<RouteComponentProps & IWorkflowProps, IWorkflowState> {
  constructor(props) {
    super(props);

    this.state = {
      toggleStep: [],
      toggleAccordionCollabStep: []
    };
  }

  public componentDidMount() {
    if (this.props.idSelectedStep != undefined && this.props.workflow.id != undefined) {
      this.selectStepTaskFromUrl();
    }
    else if (this.props.workflow.id != undefined && this.props.workflow.id != null) {
      this.selectDefaultStepTask();
    }
  }

  componentDidUpdate(prevProps: IWorkflowProps, prevState: IWorkflowState) {
    if (this.reloadComponentDidUpdate(prevProps) == true){
      if (this.props.idSelectedStep != undefined && this.props.workflow.id != undefined) {
        this.selectStepTaskFromUrl();
      }
      else if (this.props.workflow.id != undefined && this.props.workflow.id != null) {
        this.selectDefaultStepTask();
      }
    }
  }
  public reloadComponentDidUpdate = (prevProps):boolean =>{
    let reload = false;
    if (((this.props.idSelectedStep != undefined && this.props.idSelectedStep != null && this.props.idSelectedStep != prevProps.idSelectedStep) || (this.props.idSelectedTask != undefined && this.props.idSelectedTask != null && this.props.idSelectedTask != prevProps.idSelectedTask))) {
      reload = true;
    }
    else if ((this.props.workflow.id != undefined && this.props.workflow.id != null) && (this.props.workflow.id != prevProps.workflow.id || (this.props.workflow.id != undefined && this.props.workflow.id != null && this.props.workflow.id == prevProps.workflow.id && this.props.workflow.WfSteps.filter(st => st.state == "En cours")[0] != undefined && prevProps.workflow.WfSteps.filter(st => st.state == "En cours")[0] != undefined && this.props.workflow.WfSteps.filter(st => st.state == "En cours")[0].id !== prevProps.workflow.WfSteps.filter(st => st.state == "En cours")[0].id))) {
      reload = true;
    }
    return reload;
  }
  
  public selectStepTaskFromUrl = () =>{
    const step = this.props.workflow.WfSteps.find(step => step.id == this.props.idSelectedStep);
    if (step != undefined && step.WfTasks != undefined && step.WfTasks != null && step.WfTasks.length > 0) { // askip c'est possible (nicolas)
      let task = null;
      let toggleStepAccordeon = [];
      if (this.props.idSelectedTask != undefined) {
        task = step.WfTasks.find(ta => ta.id == this.props.idSelectedTask);
        if(task.userIdx != Store.userConnected.id){
          toggleStepAccordeon.push(step.id);
        }
      }
      else if(this.props.idSelectedTask == undefined && step.WfTasks.filter(ta => ta.userIdx == Store.userConnected.id).length > 0){
        task = step.WfTasks.filter(ta => ta.userIdx == Store.userConnected.id)[0];
      }
      else if(this.props.idSelectedTask == undefined && (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin" || this.props.workflow.Document.userId == Store.userConnected.id)){
        task = step.WfTasks[0];
        toggleStepAccordeon.push(step.id);
      }
      const nextStep = this.props.workflow.WfSteps.find(s => s.rankStep == step.rankStep + 1);
      
      let toggleStep = [];
      
      if (step != null) {
        toggleStep.push(step.id);
        if (step.state == "En cours" && nextStep != undefined) {
          toggleStep.push(nextStep.id);
        }
        if(task != null){
          this.selectTask(task, step, true);
        }else{
          Store.myStepTaskSelectionned = undefined;
        }
      }
      this.setState({ toggleStep: toggleStep,toggleAccordionCollabStep:toggleStepAccordeon });
    }
  }
  public selectDefaultStepTask = () =>{
    const checkTask = this.checkIfTaskForUserConnected(this.props.workflow, Store.userConnected.id);
      const nextTask = this.checkNextTaskForUserConnected(this.props.workflow, checkTask);
      const toggleStep = [];
      let toggleStepAccordeon = [];
      if (checkTask != null) {
        this.selectTask(checkTask.task, checkTask.step, true);
        toggleStep.push(checkTask.step.id);
        if(checkTask.task.userIdx != Store.userConnected.id){
          toggleStepAccordeon.push(checkTask.step.id);
        }
        
        if (checkTask.step.state == "En cours" && nextTask != null) {
          toggleStep.push(nextTask.step.id);
        }
      } else {
        Store.myStepTaskSelectionned = undefined;
      }
      this.setState({ toggleStep: toggleStep,toggleAccordionCollabStep:toggleStepAccordeon });
  }
  public checkIfTaskForUserConnected = (workflow: IDBWorkflow, idUserConnected: number): { step: IDBWfStep, task: IDBWfTask } | null => {
    if (workflow.id != undefined && workflow.id != null && idUserConnected != undefined && idUserConnected != null) {
      const stepEnCours: IDBWfStep[] = workflow.WfSteps.filter(step => step.state == "En cours");
      if (stepEnCours.length > 0) {
        let myTaskEnCours : IDBWfTask[] =[];
        if (stepEnCours[0].WfTasks.filter(ta => ta.userIdx == idUserConnected).length > 0) {
          myTaskEnCours.push(stepEnCours[0].WfTasks.filter(ta => ta.userIdx == idUserConnected)[0]);
        }else{
          myTaskEnCours= stepEnCours[0].WfTasks.filter(task => {
            if (task.state == "En cours") {
              if (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin" || workflow.Document.userId == idUserConnected) {
                return true
              }
            }
          });

        }
        
        if (myTaskEnCours.length > 0) {
          return {
            step: stepEnCours[0],
            task: myTaskEnCours[0]
          }
        }
        else {
          return null;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  public checkNextTaskForUserConnected = (workflow: IDBWorkflow, stepNtask): { step: IDBWfStep, task: IDBWfTask } | null => {
    if (workflow.id != undefined && workflow.id != null && stepNtask != null) {
      const nextStep: IDBWfStep[] = workflow.WfSteps.filter(step => step.rankStep == stepNtask.step.rankStep + 1);
      if (nextStep.length > 0 && nextStep[0].WfTasks.length > 0) {
        return {
          step: nextStep[0],
          task: nextStep[0].WfTasks[0]
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  // Fonction appelée pour plier ou déplier une étape
  public toggleStep = (idStep: number) => event => {
    const findStep = this.state.toggleStep.filter(idS => idS == idStep);
    let stepActual = this.state.toggleStep;
    if (findStep.length > 0) {
      const findStep = stepActual.filter(step => step != idStep);
      stepActual = findStep;
    }
    else {
      stepActual.push(idStep);
    }
    this.setState({ toggleStep: stepActual });

    const query = useQuery();
    const s = query.get('s');
    if (s != undefined && s != null && s.length > 0) {
      query.set("s", idStep.toString());
    }
    else {
      query.append("s", idStep.toString());
    }

    const t = query.get('t');
    if (t != undefined && t != null && t.length > 0) {
      query.delete("t");
    }
    window.history.replaceState(null, null, '/document?' + query);
  }

  // Fonction pour convertir les dates pour l'affichage
  public convertDateToDisplay = (date) => {
    if (date != undefined && date != null) {
      const theDate = new Date(date);
      const day = theDate.getDate() >= 10 ? theDate.getDate() : "0" + theDate.getDate();
      const month = (theDate.getMonth() + 1) >= 10 ? (theDate.getMonth() + 1) : "0" + (theDate.getMonth() + 1);
      return day + "/" + month + "/" + theDate.getFullYear();
    }
    else {
      return "";
    }
  }

  public convertName = (task: IDBWfTask, step: IDBWfStep) => {
    if (task.User != undefined && task.User != null) {
      let titleHover = task.User.email
      if (task.isDelegate && task.UserFrom != undefined && task.UserFrom != null) {
        titleHover = "\'" + task.User.email + "\'" + " délégué(e) de " + task.UserFrom.firstname + " " + task.UserFrom.lastname
      }
      return { name: task.User.firstname + " " + task.User.lastname, email: titleHover }
      //}
    } else {
      return { name: "utilisateur introuvable", email: "utilisateur introuvable" }
    }
  }
  // Fonction appelée lorsqu'on selectionne une tache dans le workflow
  public selectTask = (task: IDBWfTask, step: IDBWfStep, init: boolean) => {
    const tempObject = { ...task };
    tempObject["WfStep"] = { ...step };
    Store.myStepTaskSelectionned = { ...tempObject };
    Store.contextualmenu = { content: "ActionsWorkflow", open: "open" };
    if (init == false) {
      const query = useQuery();
      const s = query.get('s');
      if (s != undefined && s != null && s.length > 0) {
        query.set("s", step.id.toString());
      }
      else {
        query.append("s", step.id.toString());
      }

      const t = query.get('t');
      if (t != undefined && t != null && t.length > 0) {
        query.set("t", task.id.toString());
      }
      else {
        query.append("t", task.id.toString());
      }
      window.history.replaceState(null, null, '/document?' + query);
    }
  }

  public renderTask = (task: IDBWfTask, step: IDBWfStep) => {

    const dateBegin = step.realDateBegin;
    const dateFin = task.dateDone;
    let stateTask = task.state;


    if (task.state != "Terminée" && task.isReturned == true) {
      stateTask = "Retourné";
    }

    let classNamesSelectable: string = "";
    const onclickParam: any = {};

    // If pour savoir si c'est une tache retournée pour lui changer la couleur
    if (task.isReturned) {
      classNamesSelectable = classNamesSelectable + " " + styles.returned;
    }

    if (this.props.mode === undefined || this.props.mode != "view") {
      onclickParam["onClick"] = (event) => { event.stopPropagation(); this.selectTask(task, step, false) };
      classNamesSelectable = classNamesSelectable + " " + styles.taskSelectable;
    }
    if (Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.id == task.id) {
      classNamesSelectable = classNamesSelectable + " " + styles.taskSelected;
    }

    const infoUser = this.convertName(task, step)

    // Affichage de l'icone postit bleu
    let postItUserConnected = false;

    if (task.TaskPostIts.length > 0) {
      if (task.userIdx == Store.userConnected.id) {
        postItUserConnected = true
      }
      task.TaskPostIts.forEach(tp => {
        if (tp.idxUser == Store.userConnected.id) {
          postItUserConnected = true
        }
      })
    }

    return (
      <TableRow {...onclickParam} className={styles.tableRowContent + " " + classNamesSelectable} key={task.id}>
        <TableCell style={{ width: "20px" }} align="center">{task.state == "Terminée" ? <Check className={styles.iconStatutTask + " " + styles.iconStatutFinish} /> : ""}</TableCell>
        <TableCell title={infoUser.email} align="left" style={task.isDelegate ? { fontStyle: 'italic' } : {}}>
          {infoUser.name}
        </TableCell>
        <TableCell align="left">{stateTask}</TableCell>
        <TableCell align="left">{this.convertDateToDisplay(dateBegin)}</TableCell>
        <TableCell align="left">{this.convertDateToDisplay(dateFin)}</TableCell>
        <TableCell style={postItUserConnected == true ? { width: "35px" } : { width: "20px" }} align="center">
          {task.typeTask.label != "Contribution" ?
            <span title={task.typeTask.label}><VisibilityOutlined className={styles.iconStatutTask} /></span>
            : ""
          }
          {postItUserConnected == true ?
            <span onClick={() => {
              task.TaskPostIts.forEach(ta => {
                const postIt = document.getElementById("PostItBleu" + ta.id);
                if (postIt != null) {
                  postIt.style.display = "block";
                }
              })
            }} title="Post it"><SpeakerNotesOutlinedIcon className={styles.iconStatutTask} /></span>
            : ""
          }
          {task.isDiffered == true && step.state == "En cours" ?
            <span title={task.isDifferedEnd != null ? "Tâche différée jusqu'au " + this.convertDateToDisplay(task.isDifferedEnd) + " inclus" : "Tâche différée"}><AccessTime className={styles.iconStatutTask} /></span>
            : ""
          }
        </TableCell>
      </TableRow>)
  }

  // Fonction appelée pour plier ou déplier les utilisateurs dans une étape collaborative
  public toggleAccordionCollabStep = (idStep: number) => event => {
    const findStep = this.state.toggleAccordionCollabStep.filter(idS => idS == idStep);
    let stepActual = this.state.toggleStep;
    if (findStep.length > 0) {
      const findStep = stepActual.filter(step => step != idStep);
      stepActual = findStep;
    }
    else {
      stepActual.push(idStep);
    }
    this.setState({ toggleAccordionCollabStep: stepActual });
  }


  render() {
    const Accordion = withStyles({
      root: {
        border: '0px',
        boxShadow: 'none',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 'auto',
        },
      },
      expanded: {},
    })(MuiAccordion);
    const AccordionSummary = withStyles({
      root: { marginBottom: "0px !important", marginTop: "0px !important", minHeight: "0px !important", padding: "0px 10px 0px 0px !important" },
      content: { margin: "0px !important" },
      expandIcon: { padding: "0px 12px" },
      expanded: { marginBottom: "0px !important", marginTop: "0px !important", minHeight: "0px !important" },
    })(MuiAccordionSummary);
    const AccordionDetails = withStyles((theme) => ({
      root: {
        padding: "8px 0px 16px !important"
      },
    }))(MuiAccordionDetails);
    if (this.props.workflow.id != undefined && this.props.workflow.id != null) {
      return (
        <div className={styles.workflowContainer}>
          <div className={styles.stepsContainer}>
            {
              this.props.workflow.WfSteps.map((step: IDBWfStep, index: number) => {
                // Déclaration de variable pour les couleurs etc liai au statut de l'étape
                let colorState: string = "";
                let classPuce: string = "";
                let colorTitle: string = "";
                let colorTimeline: string = styles.default;
                let colorProgressTimeLine: string = "";

                const asTaskReturned: boolean = step.WfTasks.filter(ta => ta.isReturned == true).length > 0 ? true : false;

                // If pour les couleurs par rapport aux états
                if (asTaskReturned == true) {
                  colorState = styles.returned;
                }
                else if (step.state == "En cours") {
                  colorState = styles.enCours;
                  classPuce = styles.stepEnCours;
                  colorTitle = styles.enCours;
                  colorProgressTimeLine = styles.enCours;
                }
                else if (step.state == "Terminée") {
                  colorState = styles.finish;
                  classPuce = styles.stepValidate;
                  colorTimeline = styles.finish;
                }

                // Mise à jour de l'état en fonction des taches et de la step
                let stateStep: string = step.state;
                if (asTaskReturned == true) {
                  stateStep = "Retourné";
                }
                else if (step.state == "En préparation") {
                  stateStep = "En attente";
                }
                const isInDirectTask = step.WfTasks.find(task => task.userIdx === Store.userConnected.id && task.anb != true) != undefined
                const gestionnaireDossierId = this.props.workflow.Document.userId
                let showOrientation: boolean = step.isStepOrientation == true && (stateStep == "En cours") && (Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin" || isInDirectTask /* || isInGroupTask */ || Store.userConnected.id == gestionnaireDossierId);
                let showSignature: boolean = step.isSignature == true && (Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin" || isInDirectTask /* || isInGroupTask */ || Store.userConnected.id == gestionnaireDossierId);

                const percentTaskEnd: number = step.WfTasks != undefined && step.WfTasks != null ? (step.WfTasks.filter(ta => ta.state == "Terminée" && ta.anb == false).length / step.WfTasks.filter(ta => ta.anb == false).length) * 100 : 0;

                // Style spécifique pour les étapes de type réponse. Pour déporter le trait de la timeline
                let test: any = {};
                if (step.isResponseStep == true) {
                  test = { style: { marginLeft: "34px" } };
                }

                let classStepContainer = styles.stepContainer;
                if (step.isResponseStep == true && this.props.workflow.WfSteps[index + 1] && this.props.workflow.WfSteps[index + 1].isResponseStep != true) {
                  classStepContainer = classStepContainer + " " + styles.stepLastResponse;
                }
                else if (this.props.workflow.WfSteps[index + 1] != undefined && this.props.workflow.WfSteps[index + 1].isResponseStep == true && step.isResponse == true) {
                  classStepContainer = classStepContainer + " " + styles.stepBeforeResponse;
                }
                const taskCollab: IDBWfTask[] = [];
                return (
                  <div className={classStepContainer} {...test} key={step.id}>
                    {
                      (index == this.props.workflow.WfSteps.length - 1) ?
                        ""
                        :
                        <React.Fragment>
                          <div className={styles.timelineLine + " " + colorTimeline}>
                            {
                              step.state == "En cours" ?
                                <div style={{ height: percentTaskEnd + "%" }} className={styles.timelineProgress + " " + colorProgressTimeLine}></div>
                                :
                                ""
                            }
                          </div>

                        </React.Fragment>
                    }
                    <div className={styles.step}>
                      <div className={styles.stepHeader + " " + colorTitle} onClick={this.toggleStep(step.id)}>
                        <div className={styles.stepPuce + " " + classPuce}>
                          {
                            stateStep == "Terminée" ? <Check className={styles.iconStatut} /> : ""
                          }
                        </div>
                        <div className={styles.stepTitle}>
                          {step.isCollab == true ?
                            <div style={{ display: "flex" }} title="Etape collaborative">{step.name}<People style={{ fontSize: 20, marginLeft: "5px" }} /></div>
                            :
                            <div>{step.name}</div>
                          }
                          <span className={styles.stepStatut + " " + styles.default + " " + colorState}>{stateStep}</span>
                        </div>
                      </div>
                      <div style={this.state.toggleStep.filter(stepId => stepId == step.id).length > 0 ? { height: "auto", opacity: 1 } : {}} className={styles.tasksContainer}>
                        <Table size="small" aria-label="Workflow table">
                          <TableHead>
                            <TableRow className={styles.tableHeader}>
                              {/* <TableCell align="left">Tâche</TableCell> */}
                              <TableCell align="left"></TableCell>
                              <TableCell align="left">
                                Utilisateur
                              </TableCell>
                              <TableCell align="left">Etat</TableCell>
                              <TableCell align="left">Début</TableCell>
                              <TableCell align="left">Fin</TableCell>
                              <TableCell align="left"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              step.WfTasks.map((task: IDBWfTask) => {
                                if (step.isCollab == true) {
                                  if (task.userIdx == Store.userConnected.id) {
                                    return this.renderTask(task, step);
                                  } else {
                                    taskCollab.push(task);
                                  }
                                }
                                else {
                                  return this.renderTask(task, step);
                                }
                              })
                            }

                          </TableBody>
                        </Table>
                        {step.isCollab == true && taskCollab.length > 0 ?
                          <div>
                            <Accordion 
                              onClick={this.toggleAccordionCollabStep(step.id)}
                              expanded={this.state.toggleAccordionCollabStep.find(idStep => idStep == step.id) != undefined ? true : false}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                Voir les utilisateurs en collaboration
                              </AccordionSummary>
                              <AccordionDetails>
                                <Table size="small" aria-label="Workflow table">

                                  <TableBody>
                                    {taskCollab.map(task => {
                                      return this.renderTask(task, step);
                                    })}
                                  </TableBody>
                                </Table>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                          : <React.Fragment />
                        }
                        {showOrientation ?
                          <Orientation mode={this.props.mode} workflow={this.props.workflow} selectedStep={step} childModels={Store.allModelWf.filter(am => am.idParent != null && am.idParent == step.idModel)} />
                          : ""
                        }
                        {showSignature ?
                          <Signature mode={this.props.mode} workflow={this.props.workflow} selectedStep={step} childModels={Store.allModelWf.filter(am => am.idParent != null && am.idParent == step.idModel)} />
                          : ""

                        }
                        {step.isResponse == true && stateStep != "Terminée" && stateStep != "Refusé" ?
                          <div>
                            Cette étape peut accueillir une réponse
                          </div>
                          : <React.Fragment></React.Fragment>

                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      );
    }
    else {
      return (
        <div className={styles.workflowContainer}>
          <p>Pas de WorkFlow pour le moment</p>
        </div>
      )
    }
  }
}

export default withRouter(Workflow);