import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import styles from "./styles/sign.module.scss";
import { IDBWfStep, IDBWorkflow, IModelWf, IDBSignType } from "./interfaces/IWorkflow";
import Store from "../store/store";
import axios from 'axios';
import Spinner from "../Spinner/Spinner";
interface ISignatureProps {
  workflow: IDBWorkflow;
  selectedStep: IDBWfStep;
  childModels: IModelWf[];
  mode: string;
}

interface ISignatureState {
  loading: boolean;
}

@observer
class Signature extends React.Component<RouteComponentProps & ISignatureProps, ISignatureState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false
    };
  }

  public onUploadOnError = async (event) => {
    this.setState({ loading: true }, () => {
      axios.post(Store.wsPath + '/1/workflows/uploadDocOnError', {
        stepId: this.props.selectedStep.id,
        idUserConnected: Store.userConnected.id
      }).then((response) => {
        if (response.data.stayOnSameProject == true) {
          this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
        } else {
          this.props.history.push("/refresh?redir=document");
        }
      }).catch(error => {
        console.error("Error 'onUploadOnError' : ", error);
        this.setState({ loading: false });
        Store.snackBar = {
          message: "Une erreur est survenue, réessayez",
          error: true,
          open: true
        }
      })
    });
  }

  public onDownloadOnError = async (event) => {
    this.setState({ loading: true }, () => {
      axios.post(Store.wsPath + '/1/workflows/downloadDocOnError', {
        stepId: this.props.selectedStep.id,
        idUserConnected: Store.userConnected.id
      }).then((response) => {
        if (response.data.stayOnSameProject == true) {
          this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
        } else {
          this.props.history.push("/refresh?redir=document");
        }
      }).catch(error => {
        console.error("Error 'onDownloadOnError' : ", error);
        this.setState({ loading: false });
        Store.snackBar = {
          message: "Une erreur est survenue, réessayez",
          error: true,
          open: true
        }
      })
    });
  }

  public selectFile = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("stepId", this.props.selectedStep.id.toString());
    formData.append("idUserConnected", Store.userConnected.id.toString());

    this.setState({ loading: true }, () => {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        }
      };

      axios.post(Store.wsPath + "/1/workflows/uploadDocOnBadFormat", formData, config).then((response) => {
        if (response.data.stayOnSameProject == true) {
          this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
        } else {
          this.props.history.push("/refresh?redir=document");
        }
      }).catch(error => {
        console.error("Error 'uploadDocOnBadFormat' : ", error);
        this.setState({ loading: false });
        Store.snackBar = {
          message: "Une erreur est survenue, réessayez",
          error: true,
          open: true
        }
      })
    });
  }

  // Fonction pour fabriquer le lien de redirection vers le site du connecteur
  public formatLinkSign: (signType: IDBSignType, stepSignDocInfos: any) => string = (signType: IDBSignType, stepSignDocInfos: any) => {
    let urlSign = "";
    switch (signType.name) {
      case "docaposte":
        if (stepSignDocInfos.idDocSignature != undefined && stepSignDocInfos.idDocSignature != null) {
          urlSign = signType.params.url.endsWith("/") ? signType.params.url + "parapheur/showDoc.action?documentid=" + stepSignDocInfos.idDocSignature : signType.params.url + "/parapheur/showDoc.action?documentid=" + stepSignDocInfos.idDocSignature;
        }
        else {
          urlSign = signType.params.url
        }
        break;

      default:
        urlSign = signType.params.url;
        break;
    }

    return urlSign;
  }

  render() {
    return (
      <div className={styles.divContainer} >
        <div className={styles.buttonContainer}>
          {
            this.props.selectedStep.signDocInfos != undefined && this.props.selectedStep.signDocInfos != null && this.props.selectedStep.signDocInfos.onError != undefined && this.props.selectedStep.signDocInfos.onError != null && this.props.selectedStep.signDocInfos.onError == true ?
              this.props.selectedStep.signDocInfos.errorType != undefined && this.props.selectedStep.signDocInfos.errorType != null ?
                this.props.selectedStep.signDocInfos.errorType == "onUpload" &&  (this.props.selectedStep.signDocInfos.countError == undefined || this.props.selectedStep.signDocInfos.countError == null || this.props.selectedStep.signDocInfos.countError < 4) ?
                  <div style={{ textAlign: "center" }}>
                    <p style={{ marginBottom: "0px", color: "red" }}>{"Une erreur de communication avec " + this.props.selectedStep.SignType.label + " s'est produite, veuillez relancer l'envoie du document."}</p>
                    <Button disabled={this.state.loading} style={{ marginBottom: "5px" }} size="small" onClick={this.onUploadOnError} className={styles.customButton} variant="contained" color="primary">
                      {this.state.loading == true ? <Spinner className={styles.spinner} size={20} label='Traitement en cours' labelPlacement='left' /> : "Renvoyer le document"}
                    </Button>
                  </div>
                  : this.props.selectedStep.signDocInfos.errorType == "onDownload" ?
                    <div style={{ textAlign: "center" }}>
                      <p style={{ marginBottom: "0px" }}>{"Votre document est en attente de récupération sur " + this.props.selectedStep.SignType.label + "."}</p>
                      <p style={{ marginBottom: "0px", color: "red" }}>{"Une erreur de communication avec " + this.props.selectedStep.SignType.label + " s'est produite, veuillez relancer la récupération du document signé."}</p>
                      <Button disabled={this.state.loading} style={{ marginBottom: "5px" }} size="small" onClick={this.onDownloadOnError} className={styles.customButton} variant="contained" color="primary">
                        {this.state.loading == true ? <Spinner className={styles.spinner} size={20} label='Traitement en cours' labelPlacement='left' /> : "Récupérer le document signé"}
                      </Button>
                    </div>
                    : this.props.selectedStep.signDocInfos.errorType == "badFormat" ?
                      <div style={{ textAlign: "center" }}>
                        <p style={{ marginBottom: "0px", color: "red" }}>{"Le format du document envoyé à " + this.props.selectedStep.SignType.label + " est incorrect."}</p>
                        <p style={{ marginBottom: "0px", color: "red" }}>{"Veuillez changer le format de votre document en pdf et le renvoyer."}</p>
                        <form id="formDocAss" encType="multipart/form-data" >
                          <div className={styles.titleContainer} style={{ display: "flex" }}>
                            <label htmlFor="btn-uploadNewDoc" style={{ width: "100%" }}>
                              <input
                                id="btn-uploadNewDoc"
                                name="btn-uploadNewDoc"
                                style={{ display: 'none' }}
                                type="file"
                                onChange={this.selectFile}
                                accept="application/pdf"
                                disabled={this.state.loading}
                              />
                              <Button disabled={this.state.loading} style={{ marginBottom: "5px" }} size="small" className={styles.customButton} component="span" variant="contained" color="primary">
                                {this.state.loading == true ? <Spinner className={styles.spinner} size={20} label='Traitement en cours' labelPlacement='left' /> : "Remplacer et renvoyer le document "}
                              </Button>
                            </label>
                          </div>
                        </form>

                      </div>
                      : <div style={{ textAlign: "center" }}>
                        <p style={{ marginBottom: "5px", color: "red" }}>{"Une erreur de communication avec " + this.props.selectedStep.SignType.label + " s'est produite, veuillez contacter un administrateur."}</p>
                      </div>
                : <div style={{ textAlign: "center" }}>
                  <p style={{ marginBottom: "5px", color: "red" }}>{"Une erreur de communication avec " + this.props.selectedStep.SignType.label + " s'est produite, veuillez contacter un administrateur."}</p>
                </div>
              : this.props.selectedStep.state == "En cours" && this.props.selectedStep.SignType != undefined && this.props.selectedStep.SignType != null ?
                <div style={{ textAlign: "center" }}>
                  <p style={{ marginBottom: "0px" }}>{"Votre document est en attente sur " + this.props.selectedStep.SignType.label + "."}</p>
                  <p style={{ marginBottom: "0px" }}>{"Après son classement cette étape sera automatiquement validée."}</p>
                  {
                    this.props.selectedStep.SignType.params.url != undefined && this.props.selectedStep.SignType.params.url != null && this.props.selectedStep.SignType.params.url != "" ?
                      <a href={this.formatLinkSign(this.props.selectedStep.SignType, this.props.selectedStep.signDocInfos)} target="_blank">
                        <Button style={{ marginBottom: "5px" }} size="small" className={styles.customButton} component="span" variant="contained" color="primary">
                          {"Acceder à " + this.props.selectedStep.SignType.label}
                        </Button>
                      </a>
                      :
                      <React.Fragment />
                  }
                </div>
                : this.props.selectedStep.state == "Terminée" && this.props.selectedStep.SignType != undefined && this.props.selectedStep.SignType != null ?
                  <div style={{ textAlign: "center" }}>
                    <p style={{ marginBottom: "0px" }}>{"Votre document a été signé et rapatrié depuis " + this.props.selectedStep.SignType.label + "."}</p>
                  </div>
                  : this.props.selectedStep.state == "En préparation" && this.props.selectedStep.SignType != undefined && this.props.selectedStep.SignType != null ?
                    <div style={{ textAlign: "center" }}>
                      <p style={{ marginBottom: "0px" }}>{"Ceci est une étape de signature, votre document sera envoyé sur " + this.props.selectedStep.SignType.label + " lorsque celle-ci passera en cours."}</p>
                    </div>
                    : <React.Fragment />


          }
        </div>
      </div>
    )
  }
}
export default withRouter(Signature);