import * as React from 'react';
import { observer } from "mobx-react";
import TextField from '@material-ui/core/TextField';
import Store from '../../store/store';
import SelectReact, { createFilter } from 'react-select';
import axios from 'axios';
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { customSingleValue, formatGroupLabel, GroupedOption, MenuList, Option } from '../../helper/reactSelectCustomRender';
import Spinner from '../../Spinner/Spinner';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IDelegation } from '../../delegation/interfaces/delegation';
import { IModelWf, ITypeForm } from '../../Workflow/interfaces/IWorkflow';
const styles = require("../styles/Delegation.scss");

interface Props {

}

interface State {
    optionModels: (GroupedOption | Option)[];
    selectedModel: number;
    selectedDateStart: string;
    selectedUserTo: number;
    selectedUserFrom: number;
    selectedDateEnd: string;
    id: number;
    isModel: boolean;
    name: string;
    idModelDelegation: number;
    loadingAll: boolean;
    loadingValidate: boolean;
    status: "enCours" | "programmée" | "terminée";
    createdBy: number;
}

@observer

class MenuDeleg extends React.Component<RouteComponentProps & Props, State> {
    public constructor(props: RouteComponentProps & Props) {
        super(props)
        this.state = {
            optionModels: [],
            selectedModel: undefined,
            selectedDateStart: undefined,
            selectedDateEnd: undefined,
            selectedUserFrom: Store.userConnected.id,
            selectedUserTo: undefined,
            isModel: false,
            id: undefined,
            name: undefined,
            idModelDelegation: undefined,
            loadingAll: true,
            loadingValidate: false,
            status: undefined,
            createdBy: undefined
        }
    }
    onChangeUserTo = (option) => {
        if (option != null && option.value != this.state.selectedUserFrom) {
            this.setState({
                selectedUserTo: option.value
            });
        }
        else {
            this.setState({
                selectedUserTo: undefined
            });
        }
    }
    onChangeUserFrom = (option) => {
        if (option != null && option.value != this.state.selectedUserTo) {
            this.setState({
                selectedUserFrom: option.value
            });
        }
        else {
            this.setState({
                selectedUserFrom: undefined
            });
        }
    }
    onChangeIsModel = () => {
        const temp = this.state.isModel;
        this.setState({
            isModel: !temp
        });
    }
    updateReactSelect = (user: any) => {
        const myUser = Store.suggestionsUser.find(suggestion => suggestion.value == user);
        return myUser;
    }
    getAllModelWf = () => {
        // axios.get(`${Store.wsPath}/1/workflows/getAllModelWf`)
        //     .then(response => {
        //         var temp = response.data.filter(model => model.idParent == null || model.idParent == undefined).map(model => { return { value: model.id, label: model.name } });
        //         temp.unshift({ value: 0, label: "Tous les modèles" });
        //         this.setState({
        //             optionModels: temp,
        //             loadingAll: false
        //         }, () => {
        //             if (Store.contextualmenu.content != "addNewDeleg") {
        //                 this.loadDeleg();
        //             }
        //         });
        //     });

        axios.get(`${Store.wsPath}/1/workflows/getAllModelWf`).then(responseModels => {
            axios.get(Store.wsPath + `/1/formulaire/GetAllFormType`).then(resTypes => {
                // On récupére les modèles parents
                const allParentModel: IModelWf[] = responseModels.data.filter(model => model.idParent == null || model.idParent == undefined);
                const allFormTypes: ITypeForm[] = resTypes.data;

                const allModelGrouped: (GroupedOption | Option)[] = [];
                // On cherche si il y a des formTypes activés pour les classer par form type
                if (allFormTypes.length > 0) {
                    allFormTypes.forEach(formType => {
                        const allModelFormType = allParentModel.filter(model => model.isForm == true && model.formType == formType.name);
                        if (allModelFormType.length > 0) {
                            allModelGrouped.push({
                                label: formType.label,
                                options: allModelFormType.map(model => { return { value: model.id, label: model.name } })
                            });
                        }
                    });
                }

                // On joute les modèles Lyflow au début du tableau
                const allModelLyflowFormated: Option[] = allParentModel.filter(model => model.isForm != true).map(model => { return { value: model.id, label: model.name } });
                allModelGrouped.unshift({
                    label: 'Lyflow',
                    options: allModelLyflowFormated,
                });

                // On ajoute la possibilité de choisir tous les modéles au début
                allModelGrouped.unshift({ value: 0, label: "Tous les modèles" });

                this.setState({ optionModels: allModelGrouped, loadingAll: false }, () => {
                    // pour afficher le nom du modèle a l'édition
                    if (Store.contextualmenu.content != "addNewDeleg") {
                        this.loadDeleg();
                    }
                });
            }).catch(error => {
                console.error("Error 'getAllModelWf' formType : ", error);
            });
        }).catch(error => {
            console.error("Error 'getAllModelWf' : ", error);
        });
    }
    getModelById = (id:number) => {
        let findModel:Option = null;
        this.state.optionModels.forEach(mod => {
            const model = mod as GroupedOption;
            if(model.options != undefined && model.options != null){
                const findInChild = model.options.find(mo => mo.value === id);
                if(findInChild != undefined) {
                    findModel = findInChild;
                }
            }
            else {
                const model = mod as Option;
                if(model.value === id) {
                    findModel = model;
                }
            }
        });
        return findModel;
    }
    componentDidMount(): void {
        this.getAllModelWf();
    }
    handlerModel = (option) => {
        this.setState({
            selectedModel: option.value
        })
    }
    handlerDateBegin = (e) => {
        e.persist();
        this.setState({
            selectedDateStart: e.target.value
        });
    }
    handlerDateEnd = (e) => {
        e.persist();
        this.setState({
            selectedDateEnd: e.target.value
        });
    }
    handlerName = (e) => {
        e.persist();
        this.setState({
            name: e.target.value
        });
    }
    loadDeleg = () => {
        if (Store.delegation != undefined && Store.contextualmenu.content === "editDeleg") {
            this.setState({
                id: Store.delegation.id,
                name: Store.delegation.name,
                selectedModel: Store.delegation.idModel != undefined && Store.delegation.idModel != null ? Store.delegation.idModel : 0,
                selectedDateStart: this.getDateFormat(Store.delegation.dateStart),
                selectedDateEnd: this.getDateFormat(Store.delegation.dateEnd),
                selectedUserTo: Store.delegation.delegateTo,
                selectedUserFrom: Store.delegation.delegateFrom,
                status: Store.delegation.status,
                idModelDelegation: Store.delegation.idModelDelegation,
                createdBy: Store.delegation.createdBy
            })
        } else if (Store.modelDelegation.model != undefined && (Store.contextualmenu.content === "startDeleg" || Store.contextualmenu.content === "programDeleg")) {
            this.setState({
                name: Store.modelDelegation.model.name,
                selectedModel: Store.modelDelegation.model.idModel != undefined && Store.modelDelegation.model.idModel != null ? Store.modelDelegation.model.idModel : 0,
                selectedUserTo: Store.modelDelegation.model.delegateTo,
                selectedUserFrom: Store.modelDelegation.model.delegateFrom,
                selectedDateStart: Store.contextualmenu.content === "startDeleg" ? this.getDateFormat(new Date().toString()) : undefined,
                idModelDelegation: Store.modelDelegation.model.id
            })
        }
    }
    getDateFormat = (oldDate: string) => {
        let dateFormated: string = "Pas de date";
        if (oldDate != null && oldDate != undefined) {
            const date = new Date(oldDate)
            const year = date.getFullYear().toString();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            dateFormated = year + "-" + (month < 10 ? "0" + month.toString() : month.toString()) + "-" + (day < 10 ? "0" + day.toString() : day.toString());
        }
        return dateFormated;
    }
    addDeleg = (event) => {
        event.preventDefault()
        this.setState({
            loadingValidate: true
        });
        const newDeleg: IDelegation = {
            id: undefined,
            name: this.state.name,
            dateStart: this.state.selectedDateStart,
            dateEnd: this.state.selectedDateEnd,
            idModel: this.state.selectedModel,
            delegateFrom: this.state.selectedUserFrom,
            delegateTo: this.state.selectedUserTo,
            createdBy: Store.userConnected.id,
            editedBy: Store.userConnected.id,
            idModelDelegation: this.state.idModelDelegation,
            isModel: this.state.isModel
        }
        const exist: boolean = this.didExist(newDeleg)
        if (!exist) {
            axios.post(Store.wsPath + "/1/delegation/CreateDelegation", { ...newDeleg }).then(res => {
                if (res.data != undefined && res.data != null && res.data.success) {
                    Store.snackBar = {
                        open: true,
                        error: !res.data.success,
                        message: res.data.message
                    };
                    this.setState({
                        loadingValidate: false
                    }, () => {
                        // Très sale ce qu'il y a en dessous mais c'est pour que la modale s'affiche sinon on le la voie pas ça redirect trop vite
                        setTimeout(() => {
                            this.props.history.push("/refresh?redir=delegation?display=" + res.data.status);
                        }, 100);
                    });
                } else {
                    Store.snackBar = {
                        open: true,
                        error: !res.data.success,
                        message: res.data.message
                    };
                    this.setState({
                        loadingValidate: false
                    })
                }
            }).catch(err => {
                Store.snackBar = {
                    open: true,
                    error: true,
                    message: "Une erreur est survenue à l'ajout de la délégation."
                };
                console.error("ERROR 'addDeleg' : ", err)
                this.setState({
                    loadingValidate: false
                });
            });
        } else {
            Store.snackBar = {
                open: true,
                error: true,
                message: "Une délégation existe déjà avec ce paramétrage."
            };
            this.setState({
                loadingValidate: false
            });
        }
    }
    editDeleg = (event) => {
        // WS
        event.preventDefault()
        this.setState({
            loadingValidate: true
        })
        const newDeleg: IDelegation = {
            id: this.state.id,
            idDelegation: this.state.id,
            name: this.state.name,
            dateStart: this.state.selectedDateStart,
            dateEnd: this.state.selectedDateEnd,
            idModel: this.state.selectedModel,
            delegateFrom: this.state.selectedUserFrom,
            delegateTo: this.state.selectedUserTo,
            createdBy: this.state.createdBy,
            editedBy: Store.userConnected.id,
            idModelDelegation: this.state.idModelDelegation
        }
        const exist: boolean = this.didExist(newDeleg)
        if (!exist) {
            axios.post(Store.wsPath + "/1/delegation/UpdateDelegationById", {
                ...newDeleg
            })
                .then(res => {
                    if (res.data != undefined && res.data != null && res.data.success) {
                        Store.snackBar = {
                            open: true,
                            error: !res.data.success,
                            message: res.data.message
                        };
                        this.setState({
                            loadingValidate: false
                        }, () => {
                            this.props.history.push("/refresh?redir=delegation?display=" + res.data.status);
                        });
                    } else {
                        Store.snackBar = {
                            open: true,
                            error: !res.data.success,
                            message: res.data.message
                        };
                        this.setState({
                            loadingValidate: false
                        })
                    }
                })
                .catch(err => {
                    Store.snackBar = {
                        open: true,
                        error: true,
                        message: "Une erreur est survenue à l'édition de la délégation."
                    };
                    console.error("ERROR 'editDeleg' : ", err)
                    this.setState({
                        loadingValidate: false
                    })
                });
        } else {
            Store.snackBar = {
                open: true,
                error: true,
                message: "Cette délégation existe déjà avec ce paramétrage."
            };
            this.setState({
                loadingValidate: false
            })
        }
    }
    didExist = (row: IDelegation) => {
        let delegs: IDelegation[] = Store.allDeleg.filter(deleg => deleg.status != "terminée" && deleg.idModel === row.idModel && (deleg.delegateFrom === row.delegateFrom || row.delegateTo === deleg.delegateFrom));
        if (Store.contextualmenu.content === "editDeleg") {
            delegs = delegs.filter(deleg => deleg.id != row.id);
        }
        const delegsDate = delegs.filter(deleg => { // filtre les delegs deja existante dans cette période
            if (deleg.dateEnd != undefined && deleg.dateEnd != null) {
                if (row.dateEnd != undefined && deleg.dateEnd != null) {
                    if (new Date(row.dateStart).getTime() > new Date(deleg.dateEnd).getTime()) {
                        return false;
                    } else {
                        return new Date(row.dateEnd) >= new Date(deleg.dateStart);
                    }
                } else {
                    return new Date(row.dateStart).getTime() <= new Date(deleg.dateEnd).getTime();
                }
            } else {
                if (row.dateEnd != undefined && deleg.dateEnd != null) {
                    return new Date(deleg.dateStart).getTime() >= new Date(row.dateEnd).getTime();
                } else {
                    return true;
                }
            }
        });

        return delegsDate.length > 0;

    }
    public render(): JSX.Element {
        const disabledField = false;
        const today = new Date();
        const todayString: string = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2);
        return (
            <form key="deleg" autoComplete="false"
                onSubmit={Store.contextualmenu.content === "editDeleg" ? this.editDeleg : this.addDeleg}
            >
                <div style={{ position: "relative" }}>
                    <h5>
                        {Store.contextualmenu.content === "editDeleg" ? "Modifier une délégation"
                            : Store.contextualmenu.content === "startDeleg" ? "Démarrer une délégation"
                                : Store.contextualmenu.content === "programDeleg" ? "Programmer une délégation"
                                    : "Créer une délégation"}
                    </h5>
                    {this.state.loadingAll ?
                        <Spinner className={styles.spinner} label='Chargement des paramètres' labelPlacement='bottom' />
                        :
                        <>
                            <div className={styles.space}></div>
                            <InputLabel>Nom de la délégation</InputLabel>
                            <TextField
                                variant='outlined'
                                type="text"
                                required
                                className={styles.datePicker}
                                InputLabelProps={{ shrink: true }}
                                value={this.state.name != undefined ? this.state.name : ""}
                                onChange={this.handlerName}

                            // disabled={Store.contextualmenu.content === "startDeleg" || Store.contextualmenu.content === "programDeleg"}
                            />
                            <div className={styles.space}></div>
                            <InputLabel>Choisir un circuit</InputLabel>
                            <div style={{ position: "relative" }}>
                                <SelectReact
                                    placeholder="Circuits..."
                                    options={this.state.optionModels}
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    value={this.state.selectedModel != undefined ? this.getModelById(this.state.selectedModel) : null}
                                    styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }), menu: base => ({ ...base, zIndex: 99999 }) }}
                                    onChange={this.handlerModel}
                                    required
                                    isClearable
                                    isDisabled={this.state.status === "enCours" && Store.contextualmenu.content === "editDeleg"}
                                    formatGroupLabel={formatGroupLabel}
                                />
                                <input className={styles.hiddenInput} required value={this.state.selectedModel != undefined ? this.getModelById(this.state.selectedModel).value : ""} ></input>
                            </div>
                            {Store.contextualmenu.content != "addNewModelDeleg" ?
                                <>
                                    <div className={styles.space}></div>
                                    <InputLabel>Date de début</InputLabel>
                                    <TextField
                                        variant='outlined'
                                        type="date"
                                        required
                                        className={styles.datePicker}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ inputProps: { min: (this.state.status === "enCours" && Store.contextualmenu.content === "editDeleg") ? null : todayString, max: this.state.selectedDateEnd } }}
                                        value={this.state.selectedDateStart != undefined ? this.state.selectedDateStart : ""}
                                        onChange={this.handlerDateBegin}
                                        disabled={this.state.status === "enCours" && Store.contextualmenu.content === "editDeleg"}
                                    />
                                    <div className={styles.space}></div>
                                    <InputLabel>Date de fin</InputLabel>
                                    <TextField
                                        variant='outlined'
                                        type="date"
                                        className={styles.datePicker}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ inputProps: { min: (this.state.selectedDateStart != undefined ? this.state.selectedDateStart : todayString) } }}
                                        value={this.state.selectedDateEnd}
                                        onChange={this.handlerDateEnd}
                                    />
                                </>
                                : ""}
                            <div className={styles.space}></div>
                            <InputLabel>Personne à décharger :</InputLabel>
                            <div style={{ position: "relative" }}>
                                <SelectReact
                                    placeholder="Utilisateurs..."
                                    options={Store.suggestionsUser.filter(user => user.value != this.state.selectedUserTo && !user.isGroup)}
                                    onChange={option => this.onChangeUserFrom(option ? option : null)}
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    value={this.state.selectedUserFrom != undefined ? this.updateReactSelect(this.state.selectedUserFrom) : ""}
                                    styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                                    components={{ SingleValue: customSingleValue, Option: MenuList }}
                                    isClearable
                                    isDisabled={Store.userConnected.typeUserLydoc != "ged" && Store.userConnected.typeUserLydoc != "admin" || (this.state.status === "enCours" && Store.contextualmenu.content === "editDeleg")}
                                />
                                <input className={styles.hiddenInput} required value={this.state.selectedUserFrom != undefined ? this.updateReactSelect(this.state.selectedUserFrom).value : ""} ></input>
                            </div>
                            <div className={styles.space}></div>
                            <InputLabel>Personne à déléguer :</InputLabel>
                            <div style={{ position: "relative" }}>
                                <SelectReact
                                    placeholder="Utilisateurs..."
                                    options={Store.suggestionsUser.filter(user => user.value != this.state.selectedUserFrom && !user.isGroup)}
                                    onChange={option => this.onChangeUserTo(option ? option : null)}
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    value={this.state.selectedUserTo != undefined ? this.updateReactSelect(this.state.selectedUserTo) : ""}
                                    styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                                    components={{ SingleValue: customSingleValue, Option: MenuList }}
                                    isClearable
                                    isDisabled={this.state.status === "enCours" && Store.contextualmenu.content === "editDeleg"}
                                />
                                <input className={styles.hiddenInput} required value={this.state.selectedUserTo != undefined ? this.updateReactSelect(this.state.selectedUserTo).value : ""} ></input>
                            </div>
                            {Store.contextualmenu.content === "addNewDeleg" ?
                                <>
                                    <div className={styles.space}></div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.isModel}
                                                onChange={this.onChangeIsModel}
                                                name="isModel"
                                                color="primary"
                                            />
                                        }
                                        label="Enregistrer en modèle"
                                    />
                                </>
                                : ""}
                            <div className={styles.space}></div>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={this.state.loadingValidate}
                            >
                                {this.state.loadingValidate ?
                                    <Spinner size={24} />
                                    :
                                    Store.contextualmenu.content === "editDeleg" ?
                                        "Modifier"
                                        : Store.contextualmenu.content === "startDeleg" ?
                                            "Démarrer"
                                            :
                                            "Valider"
                                }
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                className={styles.btnCancel}
                                color="default"
                                onClick={() => { Store.contextualmenu.open = "hidden"; Store.contextualmenu.content = undefined; }}
                            >
                                Annuler
                            </Button>
                        </>}
                </div>
            </form>
        )
    }
}
export default withRouter(MenuDeleg);